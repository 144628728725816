<script setup lang="ts">
defineProps({
  src: {
    type: String,
    required: true,
  },
  copyright: {
    type: String,
    required: true,
  },
  contentShortKey: {
    type: String,
    required: true,
  },
  contentLongKey: {
    type: String,
    required: false,
  },
})

const expanded = ref(false)
const expandableElement = ref<HTMLInputElement | null>(null)

function toggleExpand() {
  if (!expandableElement.value || !expandableElement.value) { return }

  const sectionHeight = expandableElement.value.scrollHeight
  // Collapse
  if (expanded.value) {
    expandableElement.value.height = sectionHeight
    requestAnimationFrame(() => {
      if (expandableElement.value) {
        expandableElement.value.style.height = `${0}px`
      }
    })
  }
  // Expand
  else {
    umTrackEvent('landing_page_card_expand_props.contentShortKey')
    expandableElement.value.style.height = `${sectionHeight}px`
  }

  expanded.value = !expanded.value
}
</script>

<template>
  <div class="group flex flex-col lg:flex-row">
    <CopyrightWrapper class="max-lg:h-80 w-full lg:h-auto lg:max-h-full lg:grow-0 lg:w-1/2 lg:group-odd:order-1 lg:group-even:order-2">
      <NuxtPicture
        :src="src"
        sizes="100vw xs:100vw sm:100vw md:100vw lg:50vw xl:50vw 2xl:50vw"
        :modifiers="{ fit_cover: '' }"
        :img-attrs="{ class: 'w-full h-full max-lg:rounded-t-3xl object-cover lg:group-odd:rounded-l-[2.5rem] lg:group-even:rounded-r-[2.5rem]' }"
        loading="lazy"
      />

      <template #copyrightNotice>
        {{ copyright }}
      </template>
    </CopyrightWrapper>
    <div class="bg-cards_green w-full lg:w-1/2 max-lg:h-fit lg:grow p-10 max-lg:rounded-b-3xl lg:group-odd:rounded-r-[2.5rem] lg:group-even:rounded-l-[2.5rem] flex flex-col items-center lg:items-start lg:group-odd:order-2 lg:group-even:order-1" @click="toggleExpand">
      <h2 class="mb-4">
        <slot name="headline" />
      </h2>
      <p class="whitespace-pre-wrap">
        {{ $t(contentShortKey) }}
      </p>
      <p ref="expandableElement" style="height: 0px;" class="lg:hidden h-fit whitespace-pre-wrap mt-6 transition-height duration-500 overflow-hidden">
        {{ contentLongKey ? $t(contentLongKey) : '' }}
      </p>
      <p class="max-lg:hidden h-fit whitespace-pre-wrap mt-6">
        {{ contentLongKey ? $t(contentLongKey) : '' }}
      </p>

      <slot name="extraContent" />

      <div v-if="contentLongKey" class="lg:hidden">
        <Icon name="mingcute:triangle-fill" class="cursor-pointer mt-4 text-2xl transition-transform origin-center duration-500" :class="{ 'rotate-180': !expanded }" />
      </div>
    </div>
  </div>
</template>
